import * as React from 'react'
import Layout from '../components/layout'
import get from 'lodash/get'
import styled from 'styled-components'
import theme from '../components/atoms/theme'
import OldCtaBlock from '../components/organisms/old-cta-block'
import moment from 'moment'
import Person from '../components/organisms/person'
import Breadcrumbs from '../components/molecules/news-breadcrumbs'
import Helmet from 'react-helmet'
import RichTextRenderer from './rich-text-renderer'
import Img from 'gatsby-image'

const largeSquiggle = require('../assets/large-squiggle-gradient.svg') as string
const redSquiggle = require('../assets/red-squiggle.svg') as string
const headerStripImage = require('../assets/header-strip.svg') as string
const headerStripImageMobile = require('../assets/header-strip-mobile.png') as string

const HeaderStrip = styled.div`
  background-image: url(${headerStripImage});
  background-repeat: no-repeat;
  background-size: cover;
  height: 6rem;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    background-image: url(${headerStripImageMobile});
    background-position: top center;
    height: 4rem;
  }
`

const Date = styled.div`
  font-size: 1.4rem;
  font-family: ${theme.fonts.primary};
  color: ${theme.colours.darkGrey2};
  text-transform: uppercase;
  letter-spacing: 0.058rem;
  margin-bottom: 0.8rem;
`

const TitleContainer = styled.div`
  margin-top: 4.2rem;
  margin-bottom: 6rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    margin: 2.8rem 0;
  }
`

const Title = styled.h2`
  max-width: 80rem;
  color: ${theme.colours.black};
  margin-bottom: 1.3rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    margin-bottom: 0.8rem;
    font-size: 2rem;
  }
`

const Squiggle = styled.img`
  width: 13.8rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 8rem;
  }
`

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`

const Image = styled(Img)`
  margin-bottom: 5rem;
`

const Article = styled.div`
  max-width: 81.8rem;
  p {
    margin-top: 0;
  }
`

const AuthorsContainer = styled.div`
  display: flex;
  margin-top: 6rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    flex-direction: column;
    margin-top: 2.4rem;
  }
`

const AuthorTitle = styled(Title)`
  margin-bottom: 0.5rem;
`

const PersonContainer = styled.div`
  max-width: 39.2rem;
  margin-right: 3.2rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    max-width: 100%;
    width: 100%;
    flex: 1 0 100%;
  }
`

const AuthorSection = styled.div`
  margin-top: 8rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    margin-top: 6rem;
  }
`

const SmallSquiggle = styled.img`
  width: 7.4rem;
`

const DesktopView = styled.div`
  display: block;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    display: none;
  }
`

const MobileView = styled.div`
  display: none;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    display: block;
  }
`

const ArticleDetails = (props): JSX.Element => {
  const article = get(props, 'pageContext.node')
  return (
    <Layout currentPath={'/news/'}>
      {article && (
        <React.Fragment>
          <Helmet title={article.title} />
          <HeaderStrip>
            <Breadcrumbs
              crumbs={[
                {
                  label: 'Home',
                  path: '/',
                },
                {
                  label: 'News',
                  path: '/news/',
                },
                {
                  label: article.title,
                  isActive: true,
                },
              ]}
            />
          </HeaderStrip>
          <TitleContainer>
            <Date>{moment(article.date).format('DD MMM YYYY')}</Date>
            <Title>{article.title}</Title>
            <Squiggle src={largeSquiggle} alt="Squiggle" />
          </TitleContainer>
          <Body>
            <Article>
              <Image fluid={article.image.fluid} />
              <RichTextRenderer richTextJson={article.body.json} />
            </Article>
            <DesktopView>
              <OldCtaBlock small />
            </DesktopView>
          </Body>
          {article.authors && (
            <AuthorSection>
              <AuthorTitle>
                About the Author{article.authors.length > 1 ? 's' : ''}
              </AuthorTitle>
              <SmallSquiggle src={redSquiggle} alt="Squiggle" />
              <AuthorsContainer>
                {article.authors.map(author => {
                  return (
                    <PersonContainer key={author.id}>
                      <Person
                        fullWidth
                        image={author.image}
                        name={author.name}
                        role={author.role}
                        bio={author.bio.bio}
                        facebook={author.facebook}
                        linkedIn={author.linkedIn}
                      />
                    </PersonContainer>
                  )
                })}
              </AuthorsContainer>
            </AuthorSection>
          )}
          <MobileView>
            <OldCtaBlock small />
          </MobileView>
        </React.Fragment>
      )}
    </Layout>
  )
}

export default ArticleDetails
